import { getHomePage } from "@/dato-api/home";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import { pageHandler } from "@/lib/pages-handler";
import Blocks from "@/components/Organisms";
import { _getSiteConfiguration } from "@/dato-api/site-config-cache";
import useInView from "react-cool-inview";
import { buildHrefLangsForHomepage, getPortfolioSlug } from "@/lib/functions";
import { fetchComponentRelations } from "@/lib/api";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";
import { useRouter } from "next/router";
const Hero = dynamic(() => import("@/components/Organisms/Hero"));

/**
 *
 * @param {array} data Homepage data
 * @returns JSX Element
 *
 */

export default function Index({ siteConfig, data, relations }) {
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  });

  const { locale } = useRouter();

  return (
    <>
      <HrefLangs links={data.hrefLangs} />
      <OgUrl
        url={
          data.hrefLangs.filter((link) => link.hrefLang === locale)?.[0]?.href
        }
      />
      <Hero data={data.hero} />
      <div ref={observe}>
        {inView && (
          <Blocks
            siteConfig={siteConfig}
            sections={data.sections}
            relations={relations}
          ></Blocks>
        )}
      </div>
    </>
  );
}

export async function getStaticProps(context) {
  const siteConfig = await _getSiteConfiguration(context.locale, false).then(
    (result) => result.siteConfiguration
  );

  const result = await getHomePage(
    context.locale,
    context.preview,
    siteConfig.id
  );
  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "brandsHomepage"
  );

  const relations = await fetchComponentRelations(
    { sections: pageData.data?.sections },
    context.locale,
    context.preview
  );

  const hrefLangs = buildHrefLangsForHomepage();

  return {
    props: {
      siteConfig: siteConfig,
      ...pageData.sst,
      data: { ...pageData.data, hrefLangs },
      preview: pageData.preview,
      relations: relations,
    },
  };
}
